import Wallet from '@front_office/Icons/Wallet';
import { Button, Typography } from 'antd';
import translator from "@/translator";

const t = translator("agent_office.navbar.");
const { Text } = Typography;

const CustomerInfoBox = ({ account_balance }) => {
  return (
    <div className="navbar_v2-wallet_with_avatar">
      <div className="navbar_v2-wallet_with_avatar__wallet">
        <Button
          href={"/front_office/payments/new?init_step=manual"}
          className="ant-custom-btn"
        >
          <Text ellipsis>{t('top_up_button')}</Text>
        </Button>
        <div className="icon">
          <Wallet />
        </div>
        <div className="sum">{account_balance}</div>
      </div>
    </div>
  );
};

const Navbar = ({ customer, account_balance }) => {
  return (
    <div className="navbar_v2">
      <div className="navbar_v2-container1">
        <CustomerInfoBox customer={customer} account_balance={account_balance} />
      </div>
      <div className="navbar_v2-container2">
        <CustomerInfoBox customer={customer} account_balance={account_balance} />
      </div>
    </div>
  );
};

export default Navbar;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { SmartCaptcha } from "@yandex/smart-captcha";

const Captcha = ({ onChange }, ref) => {
  const [resetCaptcha, setResetCaptcha] = useState(0);
  const [token, setToken] = useState("");

  const reset = () => {
    setToken(""), setResetCaptcha((prev) => prev + 1);
  };
  const getValue = () => {
    return token;
  };

  useImperativeHandle(ref, () => ({
    reset,
    getValue,
  }));

  return (
    <div className="captcha">
      <SmartCaptcha
        key={resetCaptcha}
        ref={ref}
        onSuccess={(value) => {
          setToken(value), onChange(value);
        }}
        sitekey={import.meta.env.VITE_RECAPCHA_CLIENT_KEY}
      />
    </div>
  );
};

export default forwardRef(Captcha);
